<template>
  <div class="container">
    <div class="list">
      <van-list v-model:loading="loading" :finished="finished" :finished-text="finishedText" @load="getData">
        <div class="item" v-for="item in list" :key="item.id" @click="router.push({ name: 'FeedbackDetail', query: { id: item.id } })">
          <div class="title">
            <div class="cat">{{ item.problem_type }}</div>
            <div class="status">
              <van-tag round size="medium" type="warning" v-if="item.replay_status == 0">未回复</van-tag
              ><van-tag round size="medium" type="success" v-if="item.replay_status == 1">已回复</van-tag>
            </div>
          </div>
          <div class="content">{{ item.problem_desc }}</div>
          <div class="time">{{ item.created_at }}</div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FeedbackList',
}
</script>
<script setup>
import { Tag, List } from 'vant'
import { ref, onMounted, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { getProblemList } from '@/api/problem'

const VanTag = Tag
const VanList = List
const store = useStore()
const router = useRouter()

const loading = ref(false)
const finished = ref(false)
const finishedText = ref('没有更多了')
const pagination = reactive({ page: 0, pageSize: 10 })
const list = ref([])

onMounted(() => {
  getData()
})

const getData = async () => {
  if (finished.value) {
    return false
  }

  loading.value = true
  pagination.page++
  if (pagination.page == 1) {
    list.value = []
  }

  let res = await getProblemList(pagination)
  loading.value = false

  if (res.data.pagination.page >= res.data.pagination.pageCount) {
    if (res.data.pagination.pageCount == 0) {
      finishedText.value = '暂无数据'
    }
    finished.value = true
  }

  if (res.data.list) {
    res.data.list.forEach(item => {
      list.value.push(item)
    })
  }
}
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  flex-flow: column;
  align-items: center;
}
.item {
  box-sizing: border-box;
  margin: 20px 0;
  width: 90vw;
  padding: 10px 20px;
  border-radius: 10px;
  position: relative;
  text-align: center;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 20px 50px 0 rgba(0, 0, 0, 0.1);
  z-index: 1;
  background: #2d2d35;
}
.title {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.title .cat {
  font-size: 15px;
  line-height: 100%;
  color: #d3d9e7;
  font-weight: 600;
}
.title .status {
  font-size: 15px;
  line-height: 100%;
  color: #d3d9e7;
  font-weight: 600;
}
.content {
  box-sizing: border-box;
  color: #b6b7cb;
  font-size: 13px;
  text-align: left;
  height: 35px;
  overflow: hidden;
}
.time {
  margin-top: 8px;
  text-align: right;
  color: #7a7c89;
  font-size: 13px;
  font-weight: 500;
}
</style>
